.navbar {
    margin-bottom: 0;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

header {
  padding: 60px 0 20px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
